import { useParams } from 'react-router-dom';
import colors from '../static/colors';
import ResponsiveContainer from '../elements/ResponsiveContainer';
import Card from '../assets/card.png';
import Card1 from '../assets/card1.png';
import Card2 from '../assets/card2.png';
import AppleLogo from '../assets/apple.svg';
import AndroidLogo from '../assets/android.svg';
import DownloadApple from '../assets/download-apple.svg';
import DownloadGoogle from '../assets/download-google.svg';
import { useEffect, useRef, useState } from 'react';
import { fetchPromotion } from '../functions/fetchPromotion';
import { fetchCode } from '../functions/fetchCode';
import {
    AgreementCheck,
    BackgroundLayer,
    ButtonContainer,
    CardContainer,
    CardImage,
    CardLogo,
    CardsContainer,
    CardsImage,
    CodeInput,
    CodeMeasure,
    CodeWrapper,
    ContactLink,
    CopyButton,
    CustomReactLoading,
    Description,
    DownloadAppButtons,
    DownloadAppContainer,
    DownloadAppText,
    Form,
    FormContainer,
    IllustrationContainer,
    Input,
    Label,
    MainContainer,
    Page,
    PersonalInfo,
    PlatformButton,
    PlatformDescription,
    PromotionLogo,
    PromotionTitle,
    RedeemButton,
    Step,
    TextBody,
    TextTitle,
    ErrorSpan,
    TextButton,
    LoadingText,
    CopyButtonLink,
    StepBold,
    ColoredLine,
} from '../components/Promotion';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { fireEvent } from '../functions/firebase';

const Promotion = () => {
    const { promotionSlug } = useParams();
    const [platform, setPlatform] = useState(null);
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');
    const [code, setCode] = useState('');
    const codeWidthRef = useRef(null);
    const codeInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [codeFontSize, setCodeFontSize] = useState(19);
    const [promotion, setPromotion] = useState({
        title: '',
        logo: null,
        isProtected: false,
        isPublic: false,
        isSet: false,
    });
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        fetchPromotion(promotionSlug).then(result => {
            if (!result.data) window.location.href = window.location.origin;
            const { publicPromotion } = result.data;
            setPromotion({ ...publicPromotion, isSet: true });
        });
    }, [promotionSlug]);

    useEffect(() => {
        const codeWidth = codeWidthRef.current?.offsetWidth;
        const maxWidth = codeInputRef.current?.offsetWidth;
        if (codeWidth > maxWidth) setCodeFontSize(codeFontSize - 1);
    }, [codeFontSize]);

    const getCode = async () => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            const result = await fetchCode(promotionSlug, platform, email, id);
            const { assignPromoCode } = result.data;
            setCode(assignPromoCode.value);
            fireEvent('code_assigned', {
                isPublic: promotion.isPublic,
                slug: promotionSlug,
                title: promotion.title,
                code: assignPromoCode.value,
            });
        } catch (e) {
            console.error(e);
            setErrorMessage(e.toString());
        }
        setIsLoading(false);
    };

    return (
        <Page id={'page'}>
            {promotion.isSet && promotion.logo ? (
                <>
                    <BackgroundLayer id={'background-layer'}>
                        <CardsContainer id={'cards-container'}>
                            <CardsImage
                                src={Card1}
                                style={{ transform: 'rotate3d(0, 0, 1, -20deg)', margin: '-7% 0px 0px 524px' }}
                            />
                            <CardsImage
                                src={Card2}
                                style={{ transform: 'rotate3d(0, 0, 1, -35deg)', margin: '-20% 0px 0px 550px' }}
                            />
                        </CardsContainer>
                        <DownloadAppContainer id={'download-container'}>
                            <DownloadAppText>Appen som styrker dine relasjoner</DownloadAppText>
                            <DownloadAppButtons>
                                <a
                                    href="https://apps.apple.com/rs/app/vi-appen/id1461463797"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={DownloadApple} alt={'Download Apple'} />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=no.viappen.viappen"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={DownloadGoogle} alt={'Download Google'} />
                                </a>
                            </DownloadAppButtons>
                        </DownloadAppContainer>
                    </BackgroundLayer>
                    <ResponsiveContainer flex={1} id={'responsive-container'}>
                        <MainContainer>
                            <FormContainer id={'form-container'}>
                                <Form>
                                    <TextTitle>Hent din</TextTitle>
                                    {/*<p style="display:none">{title}</p>*/}
                                    <TextTitle color={colors.TEXT_LIGHT_GREEN}>promo kode</TextTitle>

                                    {code ? (
                                        <CodeWrapper>
                                            <Description>DETTE MÅ DU GJØRE FOR Å AKTIVERE PLUSS:</Description>
                                            {platform === 'IOS' ? (
                                                <>
                                                    <Step>
                                                        <StepBold>1.</StepBold>
                                                        Last ned VI-appen og opprett en brukerprofil.
                                                    </Step>
                                                    <ColoredLine color={'#95a5af'} opacity={0.4}></ColoredLine>
                                                    <Step>
                                                        <StepBold>2.</StepBold>
                                                        Klikk deretter på knappen under fra din mobiltelefon. Om du ikke
                                                        er på mobilen nå -&gt; sjekk mailen vi har sendt deg med samme
                                                        link, som du da åpner fra din mobil. Koden kan også legges inn
                                                        manuelt i appen ved å gå til hjem-skjermen i VI-appen, trykke på
                                                        tannhjulet oppe til høyre og deretter “VI-appen PLUSS”.
                                                    </Step>
                                                    <ColoredLine color={'#95a5af'} opacity={0.4}></ColoredLine>
                                                    <Step>
                                                        <StepBold>3.</StepBold>
                                                        Aksepter å starte gratisperiode for VI-appen PLUSS. Deretter vil
                                                        PLUSS bli aktivert innen noen minutter :)
                                                    </Step>
                                                </>
                                            ) : (
                                                <>
                                                    <Step>
                                                        <StepBold>1.</StepBold>
                                                        Last ned VI-appen og opprett en brukerprofil.
                                                    </Step>
                                                    <ColoredLine color={'#95a5af'} opacity={0.4}></ColoredLine>
                                                    <Step>
                                                        <StepBold>2.</StepBold>
                                                        Fra hjem-skjermen i VI-appen trykker du på tannhjulet oppe til
                                                        høyre og deretter på “VI-appen PLUSS”. Trykk så på “Promo kode
                                                        fra en partner?”. Følg stegene og legg inn koden du ser under.
                                                    </Step>
                                                    <ColoredLine color={'#95a5af'} opacity={0.4}></ColoredLine>
                                                    <Step>
                                                        <StepBold>3.</StepBold>
                                                        Aksepter å starte gratisperiode for VI-appen PLUSS. Deretter vil
                                                        PLUSS bli aktivert innen noen minutter :)
                                                    </Step>
                                                </>
                                            )}
                                            <CodeInput
                                                ref={codeInputRef}
                                                value={code}
                                                disabled={true}
                                                style={{ fontSize: `${codeFontSize}px` }}
                                            />
                                            <CodeMeasure style={{ fontSize: `${codeFontSize}px` }} ref={codeWidthRef}>
                                                {code}
                                            </CodeMeasure>
                                            {platform === 'IOS' ? (
                                                <>
                                                    <CopyToClipboard text={code}>
                                                        <CopyButtonLink
                                                            href={`https://apps.apple.com/redeem?ctx=offercodes&id=1461463797&code=${code}`}
                                                        >
                                                            Løs inn i App Store
                                                        </CopyButtonLink>
                                                    </CopyToClipboard>
                                                    <TextButton>Kopier din promo kode</TextButton>
                                                </>
                                            ) : (
                                                <CopyToClipboard text={code}>
                                                    <CopyButton>Kopier din promo kode</CopyButton>
                                                </CopyToClipboard>
                                            )}
                                        </CodeWrapper>
                                    ) : (
                                        <>
                                            <TextBody>
                                                Her henter du din promo kode som gir deg gratis tilgang til VI-appen
                                                PLUSS for en periode. Fyll ut skjemaet under 😀
                                            </TextBody>
                                            <PersonalInfo>
                                                <Label>E-post:</Label>
                                                <Input
                                                    value={email}
                                                    onChange={element => setEmail(element.target.value)}
                                                />
                                                {promotion.isProtected ? (
                                                    <>
                                                        <Label>Your ID:</Label>
                                                        <Input
                                                            value={id}
                                                            onChange={element => setId(element.target.value)}
                                                        />
                                                    </>
                                                ) : null}
                                                {platform ? (
                                                    <>
                                                        <AgreementCheck>
                                                            <input
                                                                type="checkbox"
                                                                onChange={c => setAccepted(c.target.checked)}
                                                            />
                                                            Jeg samtykker til
                                                            <a
                                                                href="https://vi-appen.no/personvern/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                personvernerklæringen.
                                                            </a>
                                                        </AgreementCheck>

                                                        <ButtonContainer>
                                                            <RedeemButton
                                                                onClick={getCode}
                                                                disabled={isLoading || !accepted}
                                                            >
                                                                {isLoading ? (
                                                                    <CustomReactLoading
                                                                        type={'bars'}
                                                                        color={'white'}
                                                                        width={'10%'}
                                                                    />
                                                                ) : (
                                                                    'Hent promo kode'
                                                                )}
                                                            </RedeemButton>
                                                        </ButtonContainer>

                                                        <TextButton onClick={() => setPlatform('')}>
                                                            ← Gå tilbake
                                                        </TextButton>

                                                        {errorMessage && (
                                                            <ErrorSpan>
                                                                Er e-posten over korrekt? Kontakt{' '}
                                                                <a
                                                                    style={{ color: '#b90000' }}
                                                                    href={'mailto:support@vi-appen.no'}
                                                                >
                                                                    support@vi-appen.no
                                                                </a>{' '}
                                                                ved spørsmål.
                                                            </ErrorSpan>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <PlatformDescription>
                                                            Hvilken telefon har du?
                                                        </PlatformDescription>
                                                        <ButtonContainer>
                                                            <PlatformButton onClick={() => setPlatform('IOS')}>
                                                                <img src={AppleLogo} alt={'apple'} /> iPhone
                                                            </PlatformButton>
                                                            <PlatformButton onClick={() => setPlatform('ANDROID')}>
                                                                <img src={AndroidLogo} alt={'android'} /> Android
                                                            </PlatformButton>
                                                        </ButtonContainer>
                                                    </>
                                                )}
                                            </PersonalInfo>
                                        </>
                                    )}
                                    <ContactLink>
                                        <strong>Kontakt:</strong>{' '}
                                        <a href="mailto:support@vi-appen.no">support@vi-appen.no</a> ved spørsmål.
                                    </ContactLink>
                                </Form>
                            </FormContainer>
                            <IllustrationContainer id={'illustration-container'}>
                                <CardContainer id={'card-container'}>
                                    <CardImage src={Card} />
                                    <CardLogo>
                                        <PromotionTitle>Gitt til deg av:</PromotionTitle>
                                        <PromotionLogo src={promotion.logo}></PromotionLogo>
                                    </CardLogo>
                                </CardContainer>
                            </IllustrationContainer>
                        </MainContainer>
                    </ResponsiveContainer>
                </>
            ) : (
                <LoadingText>Laster side…</LoadingText>
            )}
        </Page>
    );
};

export default Promotion;
