import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import NavigationBar from './NavigationBar';

const MainContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Layout = () => {
    return (
        <MainContainer id={'main-container'}>
            <NavigationBar />
            <Outlet />
        </MainContainer>
    );
};

export default Layout;
