const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const query = `
        mutation assignPromoCode($input: AssignPromoCodeInput!) {
            assignPromoCode(assignPromoCodeInput: $input) {
                value
            }
        }
`;

export const fetchCode = (slug, platform, email, uid) => {
    const variables = {
        input: {
            slug,
            platform,
            email,
            uid,
        },
    };

    return fetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    }).then(res => res.json());
};
