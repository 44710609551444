const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const query = `
            query publicPromotion($input: PublicPromotionInput!) {
                publicPromotion(publicPromotionInput: $input) {
                    title,
                    logo,
                    isProtected,
                    isPublic
                }
            }
        `;

export const fetchPromotion = slug => {
    const variables = {
        input: {
            slug,
            platform: 'ANDROID',
        },
    };

    return fetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({
            query,
            variables,
        }),
    }).then(res => res.json());
};
