import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: 'AIzaSyDe_5e0jxfPeO-dEGbDTxmM_8dPFliCOMk',
//     authDomain: 'vi-appen-dev.firebaseapp.com',
//     projectId: 'vi-appen-dev',
//     storageBucket: 'vi-appen-dev.appspot.com',
//     messagingSenderId: '205818343581',
//     appId: '1:205818343581:web:ae572c91c8a6a4e8766474',
//     measurementId: 'G-3H7KRJ242D',
// };

const firebaseConfig = {
    apiKey: 'AIzaSyAEhuj1hbCquBvE5rCQcV45c2XNxO3Vbjk',
    authDomain: 'vi-appen-prod.firebaseapp.com',
    projectId: 'vi-appen-prod',
    storageBucket: 'vi-appen-prod.appspot.com',
    messagingSenderId: '1056606523799',
    appId: '1:1056606523799:web:bb59ddd72620713c9ab4d2',
    measurementId: 'G-8X63P07TFB',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const fireEvent = (eventName, eventData = {}) => {
    logEvent(analytics, eventName, eventData);
};
