import { useState } from 'react';
import styled from 'styled-components';

const Helper = () => {
    const [raw, setRaw] = useState('');
    const [parsed, setParsed] = useState('');
    const parse = raw => {
        const p = raw
            .trim()
            .split(' ')
            .map(code => `"${code}"`);
        setParsed(p.toString());
    };
    return (
        <div>
            <h1>Helper</h1>

            <CustomInput
                onChange={e => {
                    setRaw(e.target.value);
                    parse(e.target.value);
                }}
                value={raw}
                placeholder={'Paste copied codes'}
            />
            <span>{raw ? raw.trim().split(' ').length : 0}</span>
            <CustomText value={parsed} placeholder={'Waiting for results...'}></CustomText>
        </div>
    );
};

const CustomInput = styled.input`
    width: 400px;
    margin: 10px;
    display: block;
`;

const CustomText = styled.textarea`
    width: 400px;
    height: 300px;
    margin: 10px;
    display: block;
`;

export default Helper;
