import styled from 'styled-components';
import colors from '../static/colors';
import Logo from '../assets/logo.svg';
import ResponsiveContainer from '../elements/ResponsiveContainer';

const NavigationBarContainer = styled.div`
    background-color: ${colors.BACKGROUND_DARK};
    width: 100%;
    padding: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const NavigationBar = () => {
    return (
        <NavigationBarContainer id={'navigation-bar'}>
            <ResponsiveContainer>
                <img src={Logo} alt={'Logo'} />
            </ResponsiveContainer>
        </NavigationBarContainer>
    );
};

export default NavigationBar;
