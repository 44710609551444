import styled from 'styled-components';

const ResponsiveContainer = styled.div`
    flex: ${({ flex }) => flex || 'unset'};
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 992px) {
        width: 97%;
    }
    }
    @media (min-width: 992px) {
        width: 97%;
    }
    @media (min-width: 1440px) {
        width: 1400px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
    @media (max-width: 780px) {
        flex: none;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`;

export default ResponsiveContainer;
