import { LoadingText } from '../components/Promotion';

const NotFound = () => {
    return (
        <LoadingText>
            Du må benytte en link fra en av våre partnere for å finne din promo kode til VI-appen :)
        </LoadingText>
    );
};

export default NotFound;
