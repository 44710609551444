import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import NotFound from './pages/NotFound';
import Promotion from './pages/Promotion';
import Helper from './pages/Helper';
import Home from './pages/Home';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path=":promotionSlug" element={<Promotion />} />
                    <Route path="/helper" element={<Helper />} />
                    <Route path="/" element={<NotFound />} />
                    <Route path="/home" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
