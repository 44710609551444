import styled from 'styled-components';
import colors from '../static/colors';
import GreenBackground from '../assets/green-background.png';
import ReactLoading from 'react-loading';

export const Page = styled.div`
    //background-color: ${colors.BACKGROUND_LIGHT};
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
`;

export const LoadingText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.2px;
    margin: auto;
    color: #1b3842;
`;

export const ContactLink = styled.div`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #6e8491;
    margin: 64px 0 0 0;

    & a {
        color: inherit;

        &:active {
            color: inherit;
        }
    }
`;

export const CardsContainer = styled.div`
    width: 100%;
    flex: 1;
    display: grid;
    justify-content: center;
    align-items: center;

    @media (max-width: 780px) {
        width: 100%;
    }
`;

export const CardsImage = styled.img`
    -webkit-user-drag: none;
    width: 520px;
`;

export const BackgroundLayer = styled.div`
    position: absolute;
    right: 0;
    flex: 1;
    width: 65%;
    height: 100%;
    min-height: calc(100vh - 48px);
    background-color: ${colors.BACKGROUND_LIGHT_GREEN};
    background-image: url(${GreenBackground});
    background-position: left bottom;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 780px) {
        width: 100%;
        height: 1200px;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;

    @media (max-width: 780px) {
        flex-direction: column-reverse;
        justify-content: center;
        flex: none;
        width: 100%;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @media (max-width: 780px) {
        align-items: center;
    }
`;

export const IllustrationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 780px) {
        height: 240px;
    }
`;

export const Form = styled.div`
    width: 50rem;
    background: ${colors.BACKGROUND_LIGHT};
    box-shadow: 0 0.2rem 1.6rem rgba(27, 56, 66, 0.1);
    border-radius: 0.8rem;
    padding: 3.7rem;

    @media (max-width: 992px) {
        width: 40rem;
    }
    @media (max-width: 780px) {
        width: 95%;
    }
`;

export const PersonalInfo = styled.div``;

export const CodeWrapper = styled.div``;

export const CardContainer = styled.div`
    width: 546px;
    height: 358px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1200px) {
        width: 400px;
        height: 262px;
    }

    @media only screen and (max-width: 992px) {
        width: 300px;
        height: 262px;
    }
`;

export const PromotionTitle = styled.p`
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #6e8491;
    user-select: none;
`;

export const PromotionLogo = styled.img`
    -webkit-user-drag: none;
    max-width: 234px;
    max-height: 100px;
`;

export const CardImage = styled.img`
    width: 100%;
    -webkit-user-drag: none;
    user-select: none;
`;

export const CardLogo = styled.div`
    position: absolute;
    width: 65%;
    margin-right: 31%;
    height: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const DownloadAppContainer = styled.div`
    margin: 50px 30px 30px auto;

    @media (max-width: 780px) {
        margin: 40px auto;
    }
`;

export const DownloadAppText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.1px;
    color: ${colors.TEXT_GRAY};
`;

export const DownloadAppButtons = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 1.4rem;

    & a {
        display: block;
        cursor: pointer;
    }

    & a:first-child {
        margin-right: 2rem;
    }
`;

export const TextTitle = styled.div`
    font-family: 'Le Monde Livre App', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 64px;
    text-align: center;
    color: ${({ color }) => color || colors.TEXT_DARK_GREEN};

    @media (max-width: 992px) {
        font-size: 48px;
    }
`;

export const TextBody = styled.div`
    margin: 3.6rem 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${colors.TEXT};

    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

export const Label = styled.label`
    border: none;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.2px;
    color: #6e8491;
`;

export const Input = styled.input`
    border: none;
    border-bottom: 2px solid #0e4f4b;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #0e4f4b;
    margin: 6px 0 31px 0;
    padding: 5px 0;

    &:focus {
        outline: none;
    }
`;

export const CodeInput = styled(Input)`
    font-size: 22px;
    line-height: 36px;
    background: white;
    text-align: center;
    letter-spacing: 5px;
    margin: 70px 0 40px 0;
    -webkit-text-fill-color: #0e4f4b;
    opacity: 1; /* required on iOS */
`;

export const CodeMeasure = styled.div`
    border: solid red;
    width: max-content;
    font-size: 22px;
    line-height: 36px;
    background: white;
    text-align: center;
    letter-spacing: 5px;
    position: absolute;
    visibility: hidden;
    pointer-events: none;
`;

export const Description = styled.p`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #95a5af;
    margin: 46px 0 12px 0;
`;

export const Step = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.1px;
    color: #95a5af;
    text-align: start;
`;

export const StepBold = styled.span`
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.1px;
    color: #95a5af;
    text-align: start;
    font-weight: bold;
    margin-right: 5pt;
`;

export const PlatformDescription = styled.p`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #1b3842;
`;

export const ErrorSpan = styled.p`
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #b90000;
`;

export const AgreementCheck = styled.label`
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: -0.1px;
    color: #1b3842;

    display: flex;
    justify-content: center;
    align-items: center;

    & a {
        color: inherit;
        margin-left: 3px;
    }

    & input {
        margin-right: 11px;
    }

    @media (max-width: 780px) {
        display: block;
        padding: 0;
        text-align: center;
    }
`;

export const B = styled.button`
    &:active {
        transform: scale3d(0.98, 0.98, 0.98);
    }
`;

export const TextButton = styled.a`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 20px 0;
    color: #10504c;
    user-select: none;

    &:active {
        transform: scale3d(0.98, 0.98, 0.98);
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    @media (max-width: 780px) {
        gap: 10px;
    }
`;

export const PlatformButton = styled(B)`
    background: #1b3842;
    border-radius: 506px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 205px;
    height: 64px;
    border: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.1px;

    img {
        margin-right: 2rem;
    }

    @media (max-width: 780px) {
        font-size: 1.5rem;
    }
`;

export const CustomReactLoading = styled(ReactLoading)`
    display: flex;
`;

export const RedeemButton = styled(B)`
    background: #1b3842;
    border-radius: 506px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    height: 64px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.1px;
    pointer-events: all;

    &:disabled {
        filter: grayscale(1);
        opacity: 0.5;
        pointer-events: none;
    }
`;

export const CopyButton = styled(RedeemButton)`
    margin: 36px 0 24px;
`;

export const CopyButtonLink = styled.a`
    margin: 36px 0 24px;
    background: #1b3842;
    border-radius: 506px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    height: 64px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.1px;
    pointer-events: all;

    &:disabled {
        filter: grayscale(1);
        opacity: 0.5;
        pointer-events: none;
    }

    &:active {
        transform: scale3d(0.98, 0.98, 0.98);
    }
`;

export const ColoredLine = ({ color, opacity }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            opacity: opacity || 1,
        }}
    />
);
