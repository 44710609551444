const colors = {
    BACKGROUND_DARK: '#1B3842',
    BACKGROUND_LIGHT: '#FFFFFF',
    BACKGROUND_LIGHT_GREEN: '#E0F3EE',
    TEXT: '#1B3842',
    TEXT_DARK_GREEN: '#11504C',
    TEXT_LIGHT_GREEN: '#1D806C',
    TEXT_LIGHTER: '#6E8491',
    TEXT_LIGHT: '#FFFFFF',
    TEXT_GRAY: '#0e4f4b',
};

export default colors;
